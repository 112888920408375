import { useMemo, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import MST from "../../components";
import EditIcon from "../../components/table-create-service/icons/EditIcon";
import DeleteIcon from "../../components/table-create-service/icons/DeleteIcon";
import Modal from "../../components/base/modal/Modal";
import Editor from "../../components/base/editor/Editor";
import { ServiceActions } from "../../app/services/service/service.slice";
import { isEmpty } from "lodash";
import LanguageSelect, {
  languages,
} from "../../components/table-create-service/LanguageSelect";

function ServiceGroupsDelete({ serviceGroup }) {
  const { name, _id: value, description } = serviceGroup;
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const [isShowModalEdit, setIsShowModalEdit] = useState(false);
  const [newName, setNewName] = useState(name);
  const [newDescription, setNewDescription] = useState(description);
  const [selectedLang, setSelectedLang] = useState("en");

  const onHide = () => {
    setIsShow(false);
  };

  const onShow = () => {
    setIsShow(true);
  };

  const onShowModalEdit = () => {
    setIsShowModalEdit(true);
  };

  const onHideModalEdit = () => {
    setIsShowModalEdit(false);
  };

  const onDelete = useCallback(() => {
    dispatch(
      ServiceActions.deleteServiceGroup({
        id: value,
        onSuccess: () => {
          onHide();
        },
      })
    );
  }, [dispatch, value]);

  const onEdit = useCallback(() => {
    if (newName.length <= 0) {
      toast.error("Yêu cầu nhập tên nhóm dịch vụ");
    } else {
      dispatch(
        ServiceActions.updateServiceGroup({
          value: value,
          name: newName,
          description: newDescription,
          onSuccess: () => {
            toast.success("Cập nhật thành công");
            onHideModalEdit();
          },
        })
      );
    }
  }, [dispatch, newDescription, newName, value]);

  const contentModelEdit = useMemo(() => {
    return (
      <div>
        <div className="modal-header">Thêm nhóm dịch vụ</div>
        <div className="modal-body">
          <div className="mb-8">Tên nhóm dịch vụ</div>
          <MST.Input
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            placeholder="Nhập tên nhóm dịch vụ"
          />
          <div className="mb-8 mt-6">Mô tả nhóm dịch vụ</div>
          <LanguageSelect
            selectedLang={selectedLang}
            onSelectLanguage={setSelectedLang}
          />
          {languages.map(
            (lang) =>
              selectedLang === lang && (
                <Editor
                  onBlur={(eventInfo, editor) => {
                    const data = editor?.getData();
                    const valueTrim = data?.trim();
                    if (isEmpty(data)) {
                      editor.setData("");
                      return;
                    }
                    setNewDescription((cur) => ({
                      ...cur,
                      [lang]: valueTrim,
                    }));
                  }}
                  data={
                    typeof newDescription !== "object" && selectedLang === "en"
                      ? newDescription || ""
                      : newDescription?.[lang] || ""
                  }
                  height="150px"
                  placeholder="Nhập mô tả"
                  key={lang + "edit service-group" + value}
                />
              )
          )}
        </div>
        <div className="modal-footer">
          <div className="d-flex jc-between">
            <div />
            <MST.Button onClick={onEdit}>Xác nhận</MST.Button>
          </div>
        </div>
      </div>
    );
  }, [newDescription, newName, onEdit, selectedLang, value]);

  const contentModal = useMemo(() => {
    return (
      <div>
        <div className="modal-header">Xoá nhóm dịch vụ</div>
        <div className="modal-body">
          Bạn có muốn xoá nhóm dịch vụ này không?
        </div>
        <div className="modal-footer">
          <div className="d-flex jc-between">
            <div />
            <div className="d-flex">
              <MST.Button type="outlined" className="mr-8" onClick={onHide}>
                Huỷ
              </MST.Button>
              <MST.Button onClick={onDelete}>Xác nhận</MST.Button>
            </div>
          </div>
        </div>
      </div>
    );
  }, [onDelete]);

  return (
    <div className="d-flex jc-center">
      <button className="btn-edit mr-8" onClick={onShowModalEdit}>
        <EditIcon />
      </button>
      <button className="btn-delete" onClick={onShow}>
        <DeleteIcon />
      </button>
      <Modal content={contentModal} isShow={isShow} onHide={onHide} />
      <Modal
        content={contentModelEdit}
        isShow={isShowModalEdit}
        onHide={onHideModalEdit}
      />
    </div>
  );
}

export default ServiceGroupsDelete;
