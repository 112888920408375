import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { OrderActions, OrderSelectors } from "./order.slice";
import { toast } from "react-toastify";
import OrderRequest from "./order.request";

function* OrderSaga() {
  yield takeLatest(OrderActions.getOrders, getOrders);
  yield takeLatest(OrderActions.getOrderById, getOrderById);
  yield takeLatest(
    OrderActions.searchCustomerByUsername,
    searchCustomerByUsername
  );
  yield takeLatest(OrderActions.setSearchNumberOrder, setSearchNumberOrder);
  yield takeLatest(OrderActions.setSearch, setSearch);
  yield takeLatest(
    OrderActions.checkYouTubeSubscriberOrders,
    checkYouTubeSubscriberOrders
  );
  yield takeLatest(OrderActions.completeOrder, completeOrder);
  yield takeLatest(OrderActions.refundOrder, refundOrder); // Thêm saga refundOrder
  yield takeLatest(OrderActions.reSendOrder, reSendOrder); // Thêm saga reSendOrder
  yield takeLatest(OrderActions.updateOrderStatus, updateOrderStatus); // Thêm saga reSendOrder
  yield takeLatest(OrderActions.handleRefundOrder, handleRefundOrder);
  yield takeLatest(OrderActions.resendAllProcessing, resendAllProcessing);
}

export default OrderSaga;
function* setSearchNumberOrder({ payload }) {
  try {
    yield delay(300);
    yield put(OrderActions.getOrders());
  } catch (error) {
    yield put(OrderActions.setOrders([]));
    toast.error(error);
  }
}

function* searchCustomerByUsername({ payload }) {
  try {
    yield delay(200);
    const rs = yield OrderRequest.searchCustomerByUsername(payload);

    if (rs.success) {
      yield put(OrderActions.setCustomerList(rs.data.customers));
    }
  } catch (error) {
    toast.error(error);
  }
}

function* getOrders({ payload = undefined }) {
  try {
    yield delay(100);

    const pagination = yield select(OrderSelectors.pagination);
    const currentCustomer = yield select(OrderSelectors.currentCustomer);
    const servicePackageName = yield select(OrderSelectors.servicesSearch);
    const search = yield select(OrderSelectors.search);
    const searchNumberOrder = yield select(OrderSelectors.searchNumberOrder);
    const status = yield select(OrderSelectors.status);

    const rs = yield OrderRequest.getOrders({
      customerID:
        currentCustomer === undefined ? undefined : currentCustomer?._id,
      page: pagination.page,
      code: search,
      orderNumber: searchNumberOrder,
      pageSize: pagination.pageSize,
      servicePackageName: servicePackageName,
      status: status,
    });
    if (rs.success) {
      yield put(OrderActions.setOrders(rs.data.orders));
      yield put(OrderActions.setPagination(rs.data.pagination));
    }
  } catch (error) {
    console.log(error);
    toast.error(error);
  }
}

function* getOrderById({ payload }) {
  try {
    yield delay(100);
    const rs = yield OrderRequest.getOrderById(payload);

    if (rs.success) {
      yield put(OrderActions.setOrderDetail(rs.data.order));
    }
  } catch (error) {
    toast.error(error);
  }
}

function* setSearch({ payload }) {
  try {
    yield delay(300);
    const pagination = yield select(OrderSelectors.pagination);
    const currentCustomer = yield select(OrderSelectors.currentCustomer);
    const servicePackageName = yield select(OrderSelectors.servicesSearch);
    const searchNumberOrder = yield select(OrderSelectors.searchNumberOrder);
    const search = yield select(OrderSelectors.search);
    const rs = yield OrderRequest.getOrders({
      customerID:
        currentCustomer === undefined ? undefined : currentCustomer?._id,
      code: search,
      orderNumber: searchNumberOrder,
      page: pagination.page,
      pageSize: pagination.pageSize,
      servicePackageName: servicePackageName,
    });
    if (rs.success) {
      yield put(OrderActions.setOrders(rs.data.orders));
      yield put(OrderActions.setPagination(rs.data.pagination));
    }
  } catch (error) {
    yield put(OrderActions.setOrders([]));
    toast.error(error);
  }
}

function* checkYouTubeSubscriberOrders() {
  try {
    yield put(OrderActions.setLoading(true));
    const rs = yield OrderRequest.checkYouTubeSubscriberOrders();
    if (rs.success) {
      // yield delay(6000);
      toast.success(`${rs.processedCount} orders completed`);
      yield put(OrderActions.setLoading(false));
    } else {
      toast.warn(rs.message);
    }
  } catch (error) {
    toast.error(error);
  } finally {
    yield put(OrderActions.setLoading(false));
  }
}

function* completeOrder({ payload }) {
  try {
    const { orderId, onSuccess } = payload;
    const rs = yield OrderRequest.completeOrder(orderId);
    if (rs.success) {
      toast.success("Order completed successfully");
      yield put(OrderActions.getOrders());
      onSuccess && onSuccess();
    } else {
      toast.warn(rs.message);
    }
  } catch (error) {
    toast.error(error);
  }
}

function* refundOrder({ payload }) {
  try {
    const { id, quantity, onSuccess } = payload;
    const rs = yield call(OrderRequest.refundOrder, id, quantity);

    if (rs.success) {
      toast.success(rs.message);
      yield put(OrderActions.getOrders());
      onSuccess && onSuccess();
    } else {
      toast.warn(rs.message);
    }
  } catch (error) {
    console.error(error);
    toast.error("Hoàn tiền không thành công");
  }
}

function* reSendOrder({ payload }) {
  try {
    const { id, quantity, onSuccess } = payload;
    const rs = yield call(OrderRequest.reSendOrder, id, quantity);

    if (rs.success) {
      toast.success(rs.message);
      yield put(OrderActions.getOrders());
      onSuccess && onSuccess();
    } else {
      toast.warn(rs.message);
    }
  } catch (error) {
    console.error(error);
    toast.error("Đặt lại đơn không thành công");
  }
}

function* updateOrderStatus({ payload }) {
  try {
    const { id, status, onSuccess } = payload;
    const rs = yield call(OrderRequest.updateOrderStatus, id, status);
    if (rs.success) {
      toast.success(rs.message);
      yield put(OrderActions.getOrders());
      onSuccess && onSuccess();
    } else {
      toast.warn(rs.message);
    }
  } catch (error) {
    console.error(error);
    toast.error("Cập nhập trạng thái đơn không thành công");
  }
}

function* handleRefundOrder({ payload }) {
  try {
    const { data, onSuccess } = payload;
    const rs = yield call(OrderRequest.handleRefundOrder, data);
    if (rs.success) {
      toast.success(rs.message);
      onSuccess && onSuccess();
    }
  } catch (error) {
    toast.error(error);
    return;
  }
}
function* resendAllProcessing({ payload }) {
  try {
    const { data, onSuccess } = payload;
    const rs = yield call(OrderRequest.resendAllProcessing, data);
    if (rs.success) {
      toast.success(rs.message);
      onSuccess && onSuccess();
    }
  } catch (error) {
    toast.error(error);
    return;
  }
}
