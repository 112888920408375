import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { VoucherActions } from "../../app/services/voucher/voucher.slice";
import SearchIcon from "../../images/icons/SearchIcon";
import "./style.css";
import {
  ServiceActions,
  ServiceSelectors,
} from "../../app/services/service/service.slice";
import MST from "../../components";

const CheckIcon = ({ size = 20 }) => {
  return (
    <svg viewBox="0 0 448 512" width={size} height={size}>
      <path
        fill="currentColor"
        d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
      />
    </svg>
  );
};

const DownIcon = ({ size = 20 }) => {
  return (
    <svg viewBox="0 0 320 512" width={size} height={size}>
      <path
        fill="currentColor"
        d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"
      />
    </svg>
  );
};

const TrashIcon = ({ size = 20 }) => {
  return (
    <svg viewBox="0 0 448 512" width={size} height={size}>
      <path
        fill="currentColor"
        d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0L284.2 0c12.1 0 23.2 6.8 28.6 17.7L320 32l96 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 96C14.3 96 0 81.7 0 64S14.3 32 32 32l96 0 7.2-14.3zM32 128l384 0 0 320c0 35.3-28.7 64-64 64L96 512c-35.3 0-64-28.7-64-64l0-320zm96 64c-8.8 0-16 7.2-16 16l0 224c0 8.8 7.2 16 16 16s16-7.2 16-16l0-224c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16l0 224c0 8.8 7.2 16 16 16s16-7.2 16-16l0-224c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16l0 224c0 8.8 7.2 16 16 16s16-7.2 16-16l0-224c0-8.8-7.2-16-16-16z"
      />
    </svg>
  );
};

const Checkbox = ({
  id,
  label,
  value = false,
  onChange = () => {},
  size = 24,
}) => {
  return (
    <>
      <input
        id={`checkbox-${id}`}
        type="checkbox"
        value={value}
        style={{ cursor: "pointer", display: "none" }}
        onChange={() => onChange()}
      />
      <div
        style={{
          width: size,
          aspectRatio: "1",
          borderRadius: "2px",
          background: value ? "#ff8900" : "#FFF",
          cursor: "pointer",
          transition: "all 0.2s",
          border: "2px solid #ff8900",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: value ? "#FFF" : "transparent",
        }}
        onClick={() => onChange()}
      >
        <CheckIcon size={14} />
      </div>
      <label
        style={{
          display: "inline-block",
          width: "100%",
          cursor: "pointer",
          textTransform: "capitalize",
        }}
        htmlFor={`checkbox-${id}`}
      >
        {label}
      </label>
    </>
  );
};

const MultiSelectServicesFilter = ({ searchText, onSearchBySelect }) => {
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const services = useSelector(ServiceSelectors.serviceLocalList);
  const dispatch = useDispatch();
  const inputRef = useRef();
  const listCheckboxRef = useRef();
  const [selectedServices, setSelectedServices] = useState([]);

  const ref = useRef();

  useEffect(() => {
    dispatch(ServiceActions.getServicesLocal());

    const handleClickOutSide = (e) => {
      if (ref && !ref.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    window.addEventListener("click", handleClickOutSide);

    return () => {
      window.removeEventListener("click", handleClickOutSide);
    };
  }, [dispatch]);

  useEffect(() => {
    if (isOpen) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    onSearchBySelect(selectedServices);
  }, [selectedServices]);

  const handleToggle = (e) => {
    if (inputRef.current.contains(e.target)) {
      inputRef.current.focus();
      setIsOpen(true);
      return;
    }
    if (isOpen && listCheckboxRef.current.contains(e.target)) {
      inputRef.current.focus();
      return;
    }
    setIsOpen((prev) => !prev);
  };

  return (
    <>
      <div
        ref={ref}
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          maxHeight: "50px",
          boxSizing: "border-box",
          padding: "0 12px",
          height: "100%",
          borderRadius: "8px",
          maxWidth: "300px",
          border: `1px solid ${isOpen ? `#ff8900` : `#e3e5e5`}`,
          transition: "all 0.2s",
        }}
        onClick={handleToggle}
      >
        <input
          style={{
            width: "100%",
            fontSize: "14px",
            marginRight: "12px",
            border: "none",
            outline: "none",
          }}
          placeholder="Chọn loại dịch vụ"
          readOnly={true}
          type="text"
          // value={selectedServices.join(", ")}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: isOpen ? "#ff8900" : "#e3e5e5",
            transition: "all 0.2s",
          }}
        >
          <DownIcon />
        </div>

        <div
          ref={listCheckboxRef}
          style={{
            position: "absolute",
            top: "calc(100% + 8px)",
            left: "0",
            right: "0",
            padding: "0 0 8px 0",
            borderRadius: "8px",
            background: "#FFF",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            maxHeight: "200px",
            overflow: "auto",
            display: `${isOpen ? `block` : `none`}`,
          }}
        >
          <div
            style={{
              position: "sticky",
              left: 0,
              right: 0,
              top: 0,
              boxShadow: `rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "#FFF",
              padding: "2px",
            }}
          >
            <input
              ref={inputRef}
              style={{
                boxSizing: "border-box",
                width: "100%",
                fontSize: "14px",
                border: "none",
                outline: "none",
                padding: "12px 8px 8px 8px",
              }}
              placeholder="Tìm kiếm theo loại dịch vụ"
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <button
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "2px solid red",
                borderRadius: "4px",
                width: "32px",
                aspectRatio: 1,
                cursor: "pointer",
                color: "#FFF",
                background: "red",
              }}
              onClick={() => setSelectedServices([])}
            >
              <TrashIcon />
            </button>
          </div>
          {services.map((item) => {
            if (
              item?.name
                ?.toLowerCase()
                .trim()
                .includes(search.toLowerCase().trim())
            ) {
              return (
                <div
                  style={{
                    padding: "10px 12px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "12px",
                  }}
                  key={item.value}
                >
                  <Checkbox
                    id={item.value}
                    value={selectedServices.includes(item._id)}
                    onChange={() => {
                      if (selectedServices.includes(item._id)) {
                        setSelectedServices((prev) =>
                          prev.filter((e) => e !== item._id)
                        );
                      } else {
                        setSelectedServices((prev) => [...prev, item._id]);
                      }
                    }}
                    label={`${item.name}`}
                  />
                </div>
              );
            }
            return null;
          })}
        </div>
      </div>
    </>
  );
};

function VoucherSearch({ searchText, searchService }) {
  const dispatch = useDispatch();

  const [textSearch, setSearchText] = useState("");
  const [serviceList, setServiceList] = useState([]);

  const handleSelectServices = (value) => {
    setServiceList(value);
    searchService(value);
  };

  const handleInputChange = (value) => {
    setSearchText(value);
    debouncedFetchData(value, serviceList);
    searchText(value);
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const debouncedFetchData = useRef(
    debounce((searchQuery, services) => {
      dispatch(
        VoucherActions.getVouchers({
          search: searchQuery,
          "service[in]": services,
        })
      );
    }, 300)
  ).current;

  useEffect(() => {
    debouncedFetchData(textSearch, serviceList); // Gọi API khi serviceList thay đổi
  }, [serviceList, textSearch]);

  return (
    <div>
      <div className="search-voucher-container">
        <div className="search-voucher-input">
          <MST.Input
            value={textSearch}
            onChange={(e) => handleInputChange(e.target.value)}
            placeholder={"Nhập tên voucher tìm kiếm"}
            right={
              <div className="voucher-search-btn">
                <SearchIcon />
              </div>
            }
          />
        </div>

        <div
          className="search-voucher-service"
          style={{
            alignSelf: "stretch",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MultiSelectServicesFilter
            // searchText={search}
            onSearchBySelect={handleSelectServices}
          />
        </div>
      </div>
    </div>
  );
}

export default VoucherSearch;